import React, {useState, useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Style from './Home.module.css';
import Banner from '../../images/LogomarkOrange.png';

import {DataContext} from '../../context/DataContext';
import {MapContext} from '../../context/MapContext';
import {AssumptionsContext} from '../../context/AssumptionsContext';
import {useAuth0} from "@auth0/auth0-react";
import {apiProvider} from '../../data/Api';

const isNumeric = (name, str) => {
    if(['start', 'end'].includes(name)) {
        let date = new Date(str);
        return date;
    } else if (isNaN(parseFloat(str))) return str;
    return parseFloat(str);
};

const Home = () => {
    const [load, setLoad] = useState(false);
    /* eslint-disable no-unused-vars */
    const [data, setData] = useContext(DataContext);
    const [map, setMap] = useContext(MapContext);
    const [assumptions, setAssumptions] = useContext(AssumptionsContext);
    const history = useHistory();

    const { getAccessTokenSilently } = useAuth0();
    const { user } = useAuth0();

    let scenarioList = data.user.scenarioList;
    let projectList = data.user.projectList;

    let [userConsent, setUserConsent] = useState(false);
    let [consentUpdate, setConsentUpdate] = useState(false);

    let [admin, setAdmin] = useState(false);
    let [laList, setLaList] = useState([]);

    const handleLoad = async event => {
        const name = event.target.getAttribute("name");
        let scenarios = [];
        scenarioList.forEach(scenario => scenarios.push(scenario.scenario_id));

        if(!name) {
            setLoad(!load);
        } else if(name === "new" | !scenarios.includes(Number(data.user.loadedScenario))) {
            history.push("/Plan");
        } else if(name === "loadSelected") {
            const assumptionsList = await apiProvider.getSingle("user/assumptions", data.user.loadedScenario, data.user.accessToken);
            let summary = {};
            assumptionsList.forEach(assumption => {
                summary[assumption.assumption_name] = assumption.assumption_name in {1: "start", 2: "end"} 
                    ? 'Hello'
                    : isNumeric(assumption.assumption_name, assumption.assumption_value);
            });
            setAssumptions(prev => {
                prev.summary = summary;
                prev.measuresSelected = summary.measuresSelected.split(",");
                prev.summary.epc = summary.epc.split(",");
                prev.summary.propertyType = summary.propertyType.split(",");
                prev.summary.tenureType = summary.tenureType.split(",");
                let selectedPostcodes = summary.selectedPostcodes.split(",");
                prev.summary.selectedPostcodes = selectedPostcodes.length === 1 & selectedPostcodes[0] === "" ? [] : selectedPostcodes;
    
                prev.measures.map(measure => {
                    if(summary.measuresSelected.includes(measure.id)) measure.checked = 'checked';
                    return measure;
                });
                prev.epcFilters.map(measure => {
                    measure.checked = '';
                    if(summary.epc.includes(measure.id)) measure.checked = 'checked';
                    return measure;
                });
                prev.propertyTypeFilters.map(measure => {
                    measure.checked = '';
                    if(summary.propertyType.includes(measure.id)) measure.checked = 'checked';
                    return measure;
                });

                return({...prev});
            });
            setMap(prev => {
                prev.mapSelected = summary.mapSelected.split(",");
                return({...prev});
            });
            history.push("/Plan");
        };
    };
    const handleSelect = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setData(prev => {
            prev.user[name] = value;
            return({...prev});
        });
    };
    const handleConsentUpdate = () => { setConsentUpdate(!consentUpdate)};
    const handleConsent = async () => {
        if(consentUpdate) {
            await apiProvider.post("user/setConsent", data.user.userArea, {}, data.user.accessToken);
            setUserConsent(true);
        };;
    }
    
    // Load data
    let getUser = async () => {
        try {
            // Get user data
            let accessToken = await getAccessTokenSilently();
            console.log(accessToken);
            let userType = user[`https://platform.c-path.com/userType`];
            let userArea = user[`https://platform.c-path.com/userArea`];
            let userAdmin = user[`https://platform.c-path.com/userAdmin`];

            console.log(user);

            setAdmin(userAdmin);
            let la_list_obj = userAdmin ? await apiProvider.getAll("geo_lsoa/la_list", accessToken) : [];
            setLaList(la_list_obj.map(a => a.local_authority_district).sort());
            
            let consent = false;
            if(userAdmin) {
                consent = true;
                setUserConsent(consent);
            } else {
                consent = await apiProvider.getSingle("user/getConsent", userArea, accessToken);
                console.log(consent)
                setUserConsent(consent[0].consent);
            }

            setData((prev) => {
                prev.user.userType = userType;
                prev.user.userArea = userArea;
                prev.user.accessToken = accessToken;
                return({...prev});
            });
        } catch (e) {
            console.log(e.message);
        }
    };

    let getData = async () => {
        try{
            const userDetails = await apiProvider.getSingle("user", data.user.userArea, data.user.accessToken);
            const projectList = await apiProvider.getSingle("user/project", data.user.userArea, data.user.accessToken);
            const scenarioList = await apiProvider.getSingle("user/scenario", data.user.userArea, data.user.accessToken);
            const employmentImpact = await apiProvider.getSingle("user/employment_impact", data.user.userArea, data.user.accessToken);
    
            let projects = [];
            projectList.forEach(x => projects.push(x.project_name));
    
            setData((prev) => {
                prev.employmentImpact = employmentImpact;
                prev.user.userDetails = userDetails[0];
                prev.user.projectList = projectList;
                prev.user.scenarioList = scenarioList;
                prev.user.userDetails = userDetails[0];
                prev.user.projectNames = projects;
    
                return({...prev});
            });
        } catch(e) {
            console.log(e.message);
        }
    }

    let updateLA = event => {
        const target = event.target;
        const value = target.value;
        setAdmin(false);
        setData((prev) => {
            prev.user.userArea = value;
            return({...prev});
        });
        getData(data);

    }

    useEffect(() => {
        getUser(data);
        getData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${Style.homeBackground}`}>
            <div className={`${Style.homeContent} container`}>
                <Row>
                    <Col md={6} className={`${Style.homeContentLeft}`}>
                        <div className="all-center">
                            <img src={Banner} alt="Thermly landing banner" className={Style.homeBanner}/>
                        </div>
                        <div className={`fw-bold nott font-primary`} style={{fontSize: "40px", lineHeight: "1.15", letterSpacing: "-1px", color: "var(--primary-color)", textAlign: "center"}}>Empowering better decisions through enhanced data and intelligence</div>
                    </Col>
                    <Col md={6} className={`${Style.flexParent}`}>
                        {
                            // <div>C-Path is being updated</div>
                            userConsent === false 
                            ? <div>
                                <div>To continue, please accept the Thermly <a href='https://platform.c-path.com/terms.pdf' target='_blank' rel="noopener noreferrer" style={{color: "blue"}}>terms of service</a>.</div>
                                <div className={Style.containerCentral}><input type="checkbox" style={{marginRight: "0.5rem"}} checked={consentUpdate} onChange={handleConsentUpdate}/>I accept.</div>
                                <div className={Style.containerCentral}>
                                    <ul className={Style.navList}>
                                        <li><span onClick={handleConsent}>Submit</span></li>
                                    </ul>
                                </div>
                            </div>

                            : admin 
                                ? <div>
                                    <label htmlFor="loadedLA" className={Style.label}>
                                        Select local authority
                                        <select 
                                            name="loadedLA"
                                            onChange={updateLA}
                                            defaultValue={'defaultValue'}
                                        >
                                            <option disabled value='defaultValue'> -- Select an LA -- </option>
                                            {
                                                laList.map(la => {return <option value={la} key={la}>{la}</option>})
                                            }
                                        </select>
                                    </label>
                                </div>
                                : <div>
                                    <Row className={`${Style.flexParent} ${Style.homeRow}`}>
                                        <p style={{textAlign: "center"}}>Create a new plan, or edit an existing one</p>
                                        <Col className={`${Style.flexParent} ${Style.homeRow} ${Style.navList} font-primary fw-bold`} md={6}>
                                            <div className={Style.planList}><div name='new' onClick={handleLoad}>New</div></div>
                                        </Col>
                                        <Col className={`${Style.flexParent} ${Style.homeRow} ${Style.navList} font-primary fw-bold`} md={6}>
                                            <div className={Style.planList}><div onClick={handleLoad}>Load</div></div>
                                        </Col>
                                    </Row>
                                    {
                                        load 
                                        ? <Row className={`${Style.flexParent} ${Style.homeRow}`}>
                                            {
                                                projectList.length === 0 
                                                ? <div className={Style.flexParent}>No saved projects.</div>
                                                : <div>
                                                    <label htmlFor="loadedProject" className={Style.label}>
                                                        Select project
                                                        <select 
                                                            name="loadedProject"
                                                            onChange={handleSelect}
                                                            defaultValue={'defaultValue'}
                                                        >
                                                            <option disabled value='defaultValue'> -- Select a project -- </option>
                                                            {
                                                                projectList.map(project => {
                                                                    return <option value={project.project_name} key={project.project_name}>{project.project_name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </label>
                                                </div>
                                            }
                                        </Row>
                                        : null
                                    }
                                    {
                                        data.user.loadedProject !== '' && load
                                        ? <Row className={`${Style.flexParent} ${Style.homeRow}`}>
                                            {
                                                scenarioList === 0
                                                ? <div className={Style.flexParent}>No saved scenarios.</div>
                                                : <div>
                                                    <label htmlFor="loadedScenario" className={Style.label}>
                                                        Select scenario
                                                        <select 
                                                            name="loadedScenario"
                                                            onChange={handleSelect}
                                                            defaultValue={'defaultValue'}
                                                        >
                                                            <option disabled value='defaultValue'> -- Select a scenario -- </option>
                                                            {
                                                                scenarioList.map(scenario => {
                                                                    if(scenario.project_name === data.user.loadedProject) return <option value={scenario.scenario_id} key={scenario.scenario_name}>{scenario.scenario_name}</option>
                                                                    else return null
                                                                })
                                                            }
                                                        </select>
                                                    </label>
                                                </div>
                                            }
                                        </Row>
                                        : null
                                    }
                                    {
                                        data.user.loadedScenario !== '' && load
                                        ? <div className={Style.flexParent}>
                                            <ul className={Style.navList}>
                                                <li className={Style.planList}><span name='loadSelected' onClick={handleLoad}>Load</span></li>
                                            </ul>
                                        </div>
                                        : null
                                    }
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Home
