import React, {useContext, useState} from 'react';
import { Row, Col } from 'react-bootstrap';

import {DataContext} from '../../context/DataContext';
import {AssumptionsContext} from '../../context/AssumptionsContext';
import {MapContext} from '../../context/MapContext';

import Style from './Modal.module.css';
import {apiProvider} from '../../data/Api';

const UserNew = (props) => {
    let {name, handleNew, value} = props;
    return (
        <div>
            <label htmlFor={name}>
                New {name} name
                <input
                    type="text"
                    name={name}
                    value={value}
                    key={name}
                    onChange={handleNew}
                />
            </label>
        </div>
    );
};

const SaveModal = () => {
    let [data, setData] = useContext(DataContext);
    let [assumptions] = useContext(AssumptionsContext);
    let [mapSelections] = useContext(MapContext);

    let [selected, setSelected] = useState({project: '-- Select a project --', scenario: '-- Select a scenario --'});
    let [entered, setEntered] = useState({project: '', scenario: ''});
    let [newInput, setNewInput] = useState({project: false, scenario: false});
    let [options, setOptions] = useState({project: data.user.projectNames, scenario: []});
    let [disabled, setDisabled] = useState({project: true, scenario: true});
    let [saved, setSaved] = useState(false);

    console.log(data)
    console.log(options)

    // Input handlers
    const handleNew = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;

        setEntered(prev => {
            prev[name] = value;
            return({...prev});
        });

        setNewInput(prev => {
            prev[name] = true;
            return({...prev});
        })

        let disabledNew = false;
        if(options[name].includes(value) | value === '') disabledNew = true;
        setDisabled(prev => {
            prev[name] = disabledNew;
            return({...prev})
        })

    };

    const handleSave = async () => {
        setDisabled({project: true, scenario: true});

        let assumptionsUpload = {...assumptions.summary};
        assumptionsUpload.mapSelected = mapSelections.mapSelected.toString();
        assumptionsUpload.measuresSelected = assumptions.measuresSelected.toString();
        assumptionsUpload.selectedPostcodes = assumptionsUpload.selectedPostcodes.toString();
        assumptionsUpload.epc = assumptionsUpload.epc.toString();
        assumptionsUpload.propertyType = assumptionsUpload.propertyType.toString();
        assumptionsUpload.tenureType = assumptionsUpload.tenureType.toString();

        let project_id = '';
        let scenario_id = '';
        if(newInput.project) {
            let id = await apiProvider.post("insert/create_project", data.user.userDetails.user_id, {project_name: entered.project}, data.user.accessToken);
            project_id = id.project_id;
        } else data.user.projectList.forEach(project => {if(project.project_name === selected.project) project_id = project.project_id});
        if(newInput.scenario) {
            let id = await apiProvider.post("insert/create_scenario", project_id, {scenario_name: entered.scenario}, data.user.accessToken);
            scenario_id = id.scenario_id;
        } else data.user.scenarioList.forEach(scenario => {if(scenario.scenario_name === selected.scenario) scenario_id = scenario.scenario_id});

        await apiProvider.post("insert/save_assumptions", scenario_id, {assumptions: assumptionsUpload}, data.user.accessToken);
        const projectList = await apiProvider.getSingle("user/project", data.user.userArea, data.user.accessToken);
        const scenarioList = await apiProvider.getSingle("user/scenario", data.user.userArea, data.user.accessToken);
        let projects = [];
        projectList.forEach(x => projects.push(x.project_name));

        setData(prev => {
            prev.user.project_id = project_id;
            prev.user.scenario_id = scenario_id;
            prev.user.projectList = projectList;
            prev.user.scenarioList = scenarioList;
            prev.user.projectNames = projects;
            return({...prev});
        });

        setSaved(true);
        setOptions({project: projects, scenario: []})
        setDisabled({project: false, scenario: false});
    };

    const handleSelect = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;

        setSelected(prev => {
            prev[name] = value;
            return({...prev});
        });
        setNewInput(prev => {
            prev[name] = value === "create_new" ? true : false;
            return({...prev});
        });

        let disabledNew = false;
        let ignore = ['-- Select a project --', '-- Select a scenario --']
        if((value === 'create_new' & entered[name] === '') | ignore.includes(value)) disabledNew = true;
        setDisabled(prev => {
            prev[name] = disabledNew;
            return({...prev})
        })

        if(name === "project") setOptions(prev => {
            let scenarios = data.user.scenarioList;
            let scenarioOptions = []
            scenarios.map(scenario => {
                if(scenario.project_name === selected.project) scenarioOptions.push(scenario.scenario_name);
                return null;
            })
            prev.scenario = scenarioOptions;
            return({...prev});
        });

    };

    // Components
    const UserSelect = (props) => {
        let {name, selectOptions, label} = props;
        return (
            <div>
                <label htmlFor={name}>
                    {label}
                    <select 
                        name={name}
                        value={selected[name]}
                        onChange={handleSelect}
                    >
                        <option disabled>-- Select a {name} --</option>
                        <option value="create_new">Create new</option>
                        {
                            selectOptions.map(x => {
                                return <option value={x} key={x}>{x}</option>
                            })
                        }
                    </select>
                </label>
            </div>
        );
    };
    const Warning = () => {
        let result = Object.values(disabled).includes(true)
        ? <Row><Col xs={12}><span style={{ color: 'red' }}>Enter both a unique project name and scenario name</span></Col></Row>
        : null
        return (result)
    };
    const Saved = () => {
        let result = saved 
        ? <Row><Col xs={12}><span style={{ color: 'green' }}>Plan saved!</span></Col></Row>
        : null
        return(result);
    };
    return (
        <div>
            <Row className={Style.selectionRow}>
                <Col md={6}>
                    {
                        options.project.length === 0
                        ? null
                        : <UserSelect name='project' selectOptions={options.project} label='Select a project, or create a new one'/>
                    }
                    {
                        options.project.length === 0 | newInput.project
                        ? <UserNew name={'project'} handleNew={handleNew} value={entered.project}/>
                        : null
                    }
                </Col>
            </Row>
            <Row className={Style.selectionRow}>
                <Col md={6} className="justify-content-md-center">
                    {
                        options.scenario.length === 0 | newInput.project
                        ? null
                        : <UserSelect name='scenario' selectOptions={options.scenario} label='Overwrite an existing plan, or create a new one'/>
                    }
                    {
                        options.scenario.length === 0 | newInput.scenario | newInput.project
                        ? <UserNew name='scenario' handleNew={handleNew} value={entered.scenario}/>
                        : null
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <span>Save plan to database</span>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <button onClick={handleSave} disabled={Object.values(disabled).includes(true)}>{newInput.project | newInput.scenario ? "Save as" : "Overwrite"}</button>
                    <Warning/>
                    <Saved/>
                </Col>
            </Row>
        </div>
    );
};

export default SaveModal;